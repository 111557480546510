import { Box, Button } from "@mui/material";
import { time } from "console";
import React, { useState } from "react";

interface Prop {
  onChosen: (hour: number, minute: number) => void;
  availableTimeslots: string[];
  askableTimeslots: string[];
  possibleTimeslots: string[];
  timeslot: string | undefined;
}

function TimePicker({ onChosen, possibleTimeslots, availableTimeslots, askableTimeslots, timeslot }: Prop) {
  //console.log(availableTimeslots)
  //console.log(availableTimeslots.indexOf(timeslot ?? ""))
  const [highlightIndex, setHighlightIndex] = useState<number>(timeslot ? possibleTimeslots.indexOf(timeslot) : -1);

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    let timeString = (event.target as HTMLInputElement).value;
    setHighlightIndex(index)

    const match = timeString.match(/(\d{2}):(\d{2})/);

    if (match) {
      const hour = parseInt(match[1]);
      const minute = parseInt(match[2]);
      onChosen(hour, minute);
    } else {
      //console.log("Wrong format of timeString is given");
    }
  };

  const SetButtonStyle = (buttonIndex: number, timeslot: string) => {
    const color = buttonIndex === highlightIndex ? "#ffffff" : undefined; // Text color
    const backgroundColor =
      buttonIndex === highlightIndex ? "primary.main" : askableTimeslots.includes(timeslot) ? "#ffe1003d" : !availableTimeslots.includes(timeslot) ? "#f5f5f5" : "#fff7f7"; // Background color
    const hoverBgColor = buttonIndex === highlightIndex ? "primary.main" : undefined;

    return {
      color: color,
      backgroundColor: backgroundColor,
      ":hover":{
        backgroundColor: hoverBgColor,
      },
      fontSize: "16px"
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        my: "10px",
        "& Button": { m: "6px",},
      }}
    >
      {possibleTimeslots.map((timeslot, index) => {
          return (
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                onClick(e, index)
              }
              disabled={!availableTimeslots.includes(timeslot)}
              value={timeslot}
              data-cy={timeslot.substring(0,2)+timeslot.substring(3,)}
              sx={SetButtonStyle(index, timeslot)}
            >
              {timeslot}
            </Button>
          );
      })}
    </Box>
  );
}

export default TimePicker;
