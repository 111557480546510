import React, { useEffect, useState } from "react";
import TimePicker from "../TimePicker";
import { PageProps } from "./PageOne";
import PageControl from "./PageControl";
import GetAvailableTimeslots from "./GetAvailableTimeslots";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useLanguageContext } from "../LanguageContextProvider";

import dayjs from "dayjs";
import { mondayLunchClosed } from "../Variables/lunch";
import { developmentObj } from "../Variables/Domain";

function PageTwo({
  onPageChanged,
  onInfoChanged,
  info,
  currentPage,
}: PageProps) {
  let { guestNum, date } = { ...info };

  const [loading, setLoading] = useState<boolean>(
    developmentObj.local_development ? false : true
  );
  const [availableTimeslot, setAvailableTimeslot] = useState<string[]>(
    developmentObj.local_development ? ["12:00"] : []
  );
  const [askableTimeslot, setAskableTimeslot] = useState<string[]>(
    developmentObj.local_development ? [] : []
  );
  const lang = useLanguageContext();

  // Page Control Checking
  let [passable, setPassable] = useState<boolean>(false);

  useEffect(() => {
    if (info.date?.hour() !== 0) {
      setPassable(true);
    }
  }, [info]);

  const handleChangePage = (pageChange: number) => {
    if (pageChange === +1) {
      if (date?.hour() !== 0) {
        onPageChanged(pageChange);
      }
    } else onPageChanged(pageChange);
  };

  // Grab Available Timeslots
  useEffect(() => {
    if (developmentObj.local_development === true) return;
    //console.log("useEffect called");
    if (date != null && guestNum != null) {
      //console.log("checkTimeslotes");
      GetAvailableTimeslots(guestNum, date).then((res) => {
        //console.log(res);
        setLoading(false);

        let available = res.availableTimeslot;
        let askable = res.askableTimeslot;

        //exclude monday lunch
        if (mondayLunchClosed && date?.day() === 1) {
          let filterMonLunch_available = available.filter((time: string) => {
            if (!["12:00", "12:15", "13:30", "13:45"].includes(time)) {
              return true;
            }
          });
          let filterMonLunch_askable = askable.filter((time: string) => {
            if (!["12:00", "12:15", "13:30", "13:45"].includes(time)) {
              return true;
            }
          });
          setAvailableTimeslot(filterMonLunch_available);
          setAskableTimeslot(filterMonLunch_askable);
        } else {
          setAvailableTimeslot(available);
          setAskableTimeslot(askable);
        }
      });
    }
  }, []);

  // Time Picker
  const handleTimeChosen = (hour: number, minute: number) => {
    let info_cloned = { ...info };

    if (info_cloned.date) {
      info_cloned.date = info_cloned.date.hour(hour).minute(minute).second(0);
      onInfoChanged(info_cloned);
    }
  };

  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {mondayLunchClosed && date?.day() === 1 ? (
            <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
              {lang === "tc" && "逢星期一中午休息"}
              {lang === "en" && "We are closed for lunch on Monday."}
            </Typography>
          ) : null}

          {date?.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD") && 
            <Typography sx={{ fontSize: "12px", color: "text.secondary", wordBreak: "break-word" }}>
              {lang === "tc" && "系統只開放6小時或以前的預約，如欲即日訂位請致電 "}
              {lang === "en" && "Online booking only opens 6 hours before each timeslot. For same-day reservation, please contact us directly through "}
              <span style={{ color: "#d94a4f", textDecoration: "underline", whiteSpace: "nowrap" }}>
                <a target="_blank" href="tel:85228511193">
                  28511193
                </a>
              </span>
              {lang === "tc" && " 查詢，或嘗試 WALK-IN"}
              {lang === "en" && " or try to WALK-IN"}
            </Typography>
          }

          {askableTimeslot.length !== 0 && (
            <>
            <Typography sx={{ pt: "4px", fontSize: "12px", color: "text.secondary", wordBreak: "break-word" }}>
              <span style={{ lineHeight: 2, borderRadius:"8px", padding: "6px", margin: "2px", backgroundColor: "#ffe1003d", fontSize: "12px", color: "text.secondary"}}>
                {lang === "tc" && "黃色時段"}
                {lang === "en" && "Timeslots in Yellow"}
              </span>
              {lang === "tc" && "已接近滿座，請透過 "}
              {lang === "en" && <span>are almost fully booked.<br/>Please contact us through </span>}
              
              <span style={{ color: "#d94a4f", textDecoration: "underline" }}>
                <a target="_blank" href="https://m.me/ivanthekozakhk">
                  Facebook Messenger
                </a>
              </span>{" "}
              {lang === "tc" && " 或致電"}
              {lang === "en" && "or at "}
              {" "}
              <span style={{ color: "#d94a4f", textDecoration: "underline", whiteSpace: "nowrap" }}>
                <a target="_blank" href="tel:85228511193">
                  28511193
                </a>
              </span>

              {lang === "tc" && " 向職員查詢"}
              {lang === "en" && " to check availability."}
            </Typography>
            </>
          )}

          {date?.format("YYYY-MM-DD") !== dayjs().format("YYYY-MM-DD") && 
          askableTimeslot.length === 0 && availableTimeslot.length === 0 ? (
            <Typography sx={{ fontSize: "12px", color: "text.secondary",  wordBreak: "break-word" }}>
              {lang === "tc" && "很抱歉，我們當天已被訂滿，請嘗試其他日子。"}
              {lang === "en" && (
                <span>
                  Sorry that we are fully booked on this day.
                  <br />
                  Please try another day.
                </span>
              )}
            </Typography>
          ) : null}
          <TimePicker
            timeslot={date?.format("HH:mm")}
            possibleTimeslots={[
              "12:00",
              "12:15",
              "12:30",
              "12:45",
              "13:00",
              "13:15",
              "13:30",
              "13:45",
              "14:00",
              "18:00",
              "18:15",
              "20:00",
              "20:15",
            ]}
            availableTimeslots={availableTimeslot}
            askableTimeslots={askableTimeslot}
            onChosen={handleTimeChosen}
          />
        </>
      )}

      <PageControl
        passable={passable}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        forwardTab="Next"
        backwardTab="Back"
      />
    </Box>
  );
}

export default PageTwo;
