import { TextField, TextFieldProps } from '@mui/material'
import React, { useState } from 'react'

type Props = TextFieldProps & {
    charLimit: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  };

const CharacterLimitTextField = ({charLimit, onChange, error, ...props}: Props) => {

    const [showError, setShowError] = useState<boolean>(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(e.target.value)
        //console.log(e.target.value.length)
        //console.log(charLimit)
        if(e.target.value.length <= charLimit){
            setShowError(false)
        }
        else{
            setShowError(true)
        }
        onChange(e)
    }

  return (
    <TextField
        {...props}
        error={showError || error}
        helperText={showError && ("exceed character limit (" + charLimit + ")")}
        onChange={handleChange}
    />
  )
}

export default CharacterLimitTextField