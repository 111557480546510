import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./fonts/AvenirNextLTPro-Regular.otf";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BookingForm from "./components/client_side/BookingForm";
import FeedbackForm from "./components/client_side/FeedbackForm";
import LanguageContextProvider from "./components/LanguageContextProvider";
import ValidPage from "./components/client_side/ValidPage";
import CancelPage from "./components/client_side/CancelPage";
import { developmentObj } from "./components/Variables/Domain";

const targetDiv = developmentObj.local_development ? "root" : "test"
// Check if the "testing" element exists in the HTML ------------------------------------------------
const testExist = document.getElementById(targetDiv);

if (testExist) {
  const root_test = ReactDOM.createRoot(
    document.getElementById(targetDiv) as HTMLElement
);

  const theme_client = createTheme({
    palette: {
      primary: {
        main: "#d94a4f", // darkred
      },
    },
    typography: {
      fontFamily: [
        "AvenirNext",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: "AvenirNextBold",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },
    },
  });

  root_test.render(
    <Suspense>
      <React.StrictMode>
        <ThemeProvider theme={theme_client}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <LanguageContextProvider>
                <Routes>
                <Route
                    index
                    element={<BookingForm />}
                  ></Route>
                  <Route
                    path="/:language/booking.php"
                    element={<BookingForm />}
                  ></Route>
                  <Route
                    path="/:language/feedback.php"
                    element={<ValidPage><FeedbackForm/></ValidPage>}
                  ></Route>
                  <Route
                    path="/:language/feedback_public.php"
                    element={<ValidPage isPublic><FeedbackForm isPublic/></ValidPage>}
                  ></Route>
                  <Route
                    path="/:language/cancel.php"
                    element={<ValidPage><CancelPage/></ValidPage>}
                  ></Route>
                </Routes>
              </LanguageContextProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </React.StrictMode>
    </Suspense>
  );
}
