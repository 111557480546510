import React, { useState } from "react";

import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import SelectedInfo from "./SelectedInfo";
import { PersonalInfoProps } from "./PersonalInfo";
import LanguageContextProvider from "../LanguageContextProvider";

interface BookingInfo {
  deposit: boolean
  specialDate: boolean
  guestNum: number | undefined;
  date: Dayjs | undefined;
  personalInfo: PersonalInfoProps;
  channel: string[];
}

export type { BookingInfo };
export const minChargeSpecialDay = 350
export const depositPerGuest = 100

function BookingForm() {
  const [page, setPage] = useState<number>(1);
  const [info, setInfo] = useState<BookingInfo>({
    deposit: false,
    specialDate: false,
    guestNum: undefined,
    date: undefined,
    personalInfo: {
      name: undefined,
      email: undefined,
      mobile: undefined,
      remark: "",
      birthday: "",
    },
    channel: []
  });

  const handlePageChange = (newPage: number) => {
    setPage(page + newPage);
  };

  const handleReturn = () => {
    setPage(1);
    setInfo({ ...info, date: info.date?.hour(0).minute(0).second(0) });
  };

  return (
    <div className="App">
        <Box
          sx={{
            boxSizing: "border-box",
            width: "350px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fdfdfd",
            p: "20px",
          }}
        >
          <SelectedInfo data={info} />

          {page === 1 && (
            <PageOne
              onPageChanged={handlePageChange}
              onInfoChanged={setInfo}
              info={info}
              currentPage={page}
            />
          )}
          {page === 2 && (
            <PageTwo
              onPageChanged={handlePageChange}
              onInfoChanged={setInfo}
              info={info}
              currentPage={page}
            />
          )}
          {page === 3 && (
            <PageThree
              onPageChanged={handlePageChange}
              onInfoChanged={setInfo}
              info={info}
              currentPage={page}
            />
          )}
          {page === 4 && (
            <PageFour info={info} returnToPageOne={handleReturn} />
          )}
        </Box>
    </div>
  );
}

export default BookingForm;
