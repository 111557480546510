import { Box, Paper, Stack, Typography } from "@mui/material";
import { useLanguageContext } from "../LanguageContextProvider";
import dayjs, { Dayjs } from "dayjs";

const Coupon = () => {
  const lang = useLanguageContext();

  const date = dayjs().format("DD/MM/YYYY");
  
  return (
    <Stack>
      {lang === "tc" ? (
        <Typography
          sx={{ textAlign: "center", color: "#000A66", m: 2, fontSize: "2rem" }}
        >
          非常感謝您的評價
        </Typography>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            color: "#000A66",
            fontWeight: 900,
            fontSize: "20px",
            wordBreak: "break-word",
            mb: 2,
          }}
        >
          Thank you for your feedback
        </Typography>
      )}

      <Paper sx={{ bgcolor: "#fff5f5", textAlign: "center" }}>
        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            borderColor: "#d32d0a",
            borderStyle: "dotted",
            borderWidth: "5px",
            m: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: { md: "5px dotted" },
              borderColor: { md: "#d32d0a" },
            }}
          >
            <img src="/public/icecream.png" width={"100px"} />
          </Box>
          <Stack sx={{ flex: 3 }}>
            {lang === "tc" ? (
              <Typography variant="h6" sx={{ color: "#3e0000", mt: 2 }}>
                甜品換領劵
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: "20px",
                  wordBreak: "break-word",
                  color: "#3e0000",
                mt: 2,
                textAlign: "center",
                }}
              >
                Dessert Voucher
              </Typography>
            )}

            {lang === "tc" ? (
              <Typography
                sx={{
                  color: "#3e0000",
                  my: 1,
                  fontSize: { xs: "2.1rem", md: "3rem" },
                  fontWeight: "bold",
                  px: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                免費是日甜品1份
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: {xs: "25px", md: "35px"},
                  wordBreak: "break-word",
                  color: "#3e0000",
                  my: 1,
                  px: 1,
                  textAlign: "center",
                }}
              >
                1 FREE Daily Dessert
              </Typography>
            )}


        {lang === "tc" ? (
          <Typography sx={{ color: "#3e0000", px: 1, fontSize: {xs: "0.8rem", md: "0.9rem"}, mb: 1, textAlign: "center" }}>
            如下次光臨並出示此截圖，可換取是日甜品一份
            <br/>
            <br/>發行日期：{date}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "0.8rem",
              wordBreak: "break-word",
              color: "#3e0000",
              mb: 1,
              px: 1,
              textAlign: "center",
            }}
          >
            Present this voucher during your next visit to get 1 free daily dessert
            <br/><br/>Issue Date: {date}
          </Typography>
        )}
          </Stack>
        </Stack>

      </Paper>
    </Stack>
  );
};

export default Coupon;
