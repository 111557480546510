import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import GuestNumPicker from "../GuestNumPicker";
import PageButton from "../PageButton";
import { BookingInfo, depositPerGuest, minChargeSpecialDay } from "./BookingForm";
import { Alert, Box, Divider, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { PickersActionBar, StaticDatePicker } from "@mui/x-date-pickers";
import PageControl from "./PageControl";
import { useLanguageContext } from "../LanguageContextProvider";

interface PageProps {
  onPageChanged: (page: number) => void;
  onInfoChanged: (data: BookingInfo) => void;
  info: BookingInfo;
  currentPage: number;
}

const isSpecialDates = (newDate: Dayjs) => {
  const specialDates = [
    dayjs("2023-12-24"),
    dayjs("2023-12-25"),
    dayjs("2023-12-31"),
  ];

  for (let i = 0; i < specialDates.length; i++) {
    if (
      newDate.month() === specialDates[i].month() &&
      newDate.date() === specialDates[i].date()
    ) {
      return true;
    }
  }
  return false;
};

export type { PageProps };

function PageOne({
  onPageChanged,
  onInfoChanged,
  info,
  currentPage,
}: PageProps) {
  let { guestNum, date, deposit, specialDate } = { ...info };

  const [passable, setPassable] = useState<boolean>(false);
  let lang = useLanguageContext();
  const deposit_threshold = 7;

  useEffect(() => {
    if (guestNum !== undefined && date !== undefined) {
      setPassable(true);
    }
  }, [info]);

  // Date Picker
  const onDateChanged = (newDate: Dayjs | null) => {
    let info_cloned = { ...info };

    if (newDate) {
      info_cloned.date = newDate.hour(0).minute(0).second(0);

      if (isSpecialDates(newDate)) {
        info_cloned.specialDate = true;
      } else {
        info_cloned.specialDate = false;
      }
      onInfoChanged(info_cloned);
    }
  };

  // Guest Picker
  const onGuestNumChanged = (newGuestNum: number) => {
    let info_cloned = { ...info };
    info_cloned.guestNum = newGuestNum;
    info_cloned.date = info_cloned.date?.hour(0).minute(0).second(0);
    if (newGuestNum >= deposit_threshold) {
      info_cloned.deposit = true;
    } else {
      info_cloned.deposit = false;
    }
    onInfoChanged(info_cloned);
  };

  // Page Control
  const handleChangePage = (page: number) => {
    if (guestNum !== undefined && date !== undefined) {
      onPageChanged(page);
    }
  };



  return (
    <Box>
      <Box sx={{ textAlign: "left", color: "text.secondary" }}>
        {lang === "tc" && "訂座人數"}
        {lang === "en" && "Party Size"}
      </Box>
      <GuestNumPicker
        onChosen={onGuestNumChanged}
        rangeValue={{
          picker: { start: 1, end: 6 },
          selector: { start: 7, end: 14 },
        }}
        guestNum={guestNum}
        sx={{ fontSize: "14px" }}
      />

      {specialDate ? (
        <Alert
          severity="info"
          sx={{
            fontSize: "12px",
            color: "text.primary",
            textAlign: "left",
            mb: "10px",
          }}
        >
          {lang === "tc" &&
            `特別日子將有最低消費$${minChargeSpecialDay}/位，並需預付$${depositPerGuest}/位訂金，訂金詳情會於稍後以電郵通知。`}
          {lang === "en" &&
            `There will be a minimum charge of $${minChargeSpecialDay}/guest for special days and deposit of $${depositPerGuest}/guest is required. Details will be sent to you by email.`}
        </Alert>
      ) : deposit ? (
        <Alert
          severity="info"
          sx={{
            fontSize: "12px",
            color: "text.primary",
            textAlign: "left",
            mb: "10px",
          }}
        >
          {lang === "tc" &&
            `6人以上訂座將收取$${depositPerGuest}/位訂金，訂金詳情會於稍後以電郵通知。`}
          {lang === "en" &&
            `Deposit of $${depositPerGuest}/guest is required for reservations for 7 people or above. Details will be sent to you by email.`}
        </Alert>
      ) : null}

      <Typography
        sx={{
          fontSize: "12px",
          color: "text.secondary",
          textAlign: "left",
          pb: "10px",
        }}
      >
        {lang === "tc" && "如需作15人或以上預約，請經"}
        {lang === "en" && (
          <span>
            If you want to make a reservation for 15 or above,
            <br />
            please contact us through
          </span>
        )}{" "}
        <span style={{ color: "#d94a4f", textDecoration: "underline" }}>
          <a target="_blank" href="https://m.me/ivanthekozakhk">
            Facebook Messenger
          </a>
        </span>{" "}
        {lang === "tc" && "或致電"}
        {lang === "en" && "or"}{" "}
        <span
          style={{
            color: "#d94a4f",
            textDecoration: "underline",
            whiteSpace: "nowrap",
          }}
        >
          <a target="_blank" href="tel:85228511193">
            28511193
          </a>
        </span>{" "}
        {lang === "tc" && "與我們聯絡"}
      </Typography>
      <Divider variant="middle" />
      <StaticDatePicker
        disablePast
        showDaysOutsideCurrentMonth
        value={date}
        onChange={onDateChanged}
        maxDate={dayjs().add(3, "month")}
        slots={{
          toolbar: () => null,
          actionBar: () => null,
        }}
        slotProps={{ day: { sx: { fontSize: "16px" } } }}
        sx={{
          ".MuiPickersCalendarHeader-labelContainer": {
            fontSize: "16px",
          },
          ".MuiDayCalendar-weekDayLabel": {
            fontSize: "16px",
          },
        }}
      />

      <PageControl
        passable={passable}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        forwardTab="Next"
        backwardTab="Back"
      />
    </Box>
  );
}

export default PageOne;
