import React, { useState } from "react";
import axios from "axios";
import { Dayjs } from "dayjs";
import { domainURL } from "../Variables/Domain";

function GetAvailableTimeslots(guestNum: number, date: Dayjs) {
  let dateString = date.format("YYYY-MM-DD");

  //// Access DataBase --------------------------------------------------------
  const res = axios
    .get(`${domainURL}/api/client/`, {
      params: {
        start_time: dateString,
        guest_num: guestNum,
        query: "client_check",
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
}

export default GetAvailableTimeslots;
