import React from "react";
import { BookingInfo } from "./BookingForm";
import { Box, Divider, SxProps } from "@mui/material";
import { useLanguageContext } from "../LanguageContextProvider";

interface Props {
  data: BookingInfo;
}

const SelectedInfo = ({ data }: Props) => {
  let { guestNum, date, personalInfo } = { ...data };
  const lang = useLanguageContext()

  //console.log(data);

  const filledButtonStyle: SxProps = {
    bgcolor: "#fff2f2",
  }

  return (
    <Box
      sx={{
        width: "100%",
        mb: "20px",
        height: "42px",
        display: "flex",
        alignItems: "stretch",
        border: "1px solid lightgrey",
        borderRadius: 1,
        bgcolor: "background.paper",
        color: "text.secondary",
        
        "> div": {
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <Box sx={guestNum !== undefined ? {...filledButtonStyle, borderRadius: "4px 0px 0px 4px"} : {}}>
        {guestNum !== undefined && lang === "tc" ? guestNum + "人": null}
        {guestNum !== undefined && lang === "en" ? guestNum + " ppl": null}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={date !== undefined ? filledButtonStyle : {}}>
        {lang === "tc" && date?.format("M[月]D")}
        {lang === "en" && date?.format("D MMM")}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={date && date?.hour() !== 0 ? filledButtonStyle : {}}>{date?.hour() !== 0 ? date?.format("HH:mm") : null}</Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={personalInfo.name !== undefined ? {...filledButtonStyle, borderRadius: "0px 4px 4px 0px"} : {}}>{personalInfo.name?.substring(0,7)}</Box>
    </Box>
  );
};

export default SelectedInfo;
