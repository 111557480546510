import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";
import { range } from "lodash";
import React, { SyntheticEvent, useState } from "react";
import { useLanguageContext } from "./LanguageContextProvider";

interface Props {
  onChosen: (num: number) => any;
  rangeValue: { picker: {start: number, end: number}, selector: {start: number, end: number} }
  guestNum: number | undefined;
  sx?: SxProps
}

function GuestNumPicker({ onChosen, rangeValue, guestNum, sx }: Props) {
  const lang = useLanguageContext();
  const guestNumArr_picker = range(rangeValue.picker.start, rangeValue.picker.end + 1);
  const guestNumArr_selector = range(rangeValue.selector.start, rangeValue.selector.end + 1);
  const [guestNum_selector, setGuestNum_selector] = useState<string>((guestNum ?? 0) >= rangeValue.selector.start ? guestNum?.toString() ?? '' : '')
  const [highlightIndex, setHighlightIndex] = useState<number>(
    guestNum ? guestNumArr_picker.indexOf(guestNum) : -1
  );

  const SetButtonStyle = (buttonIndex: number) => {
    const color = buttonIndex === highlightIndex ? "#ffffff" : undefined; // Text color
    const backgroundColor =
      buttonIndex === highlightIndex ? "primary.main" : undefined; // Background color
    const hoverBgColor =
      buttonIndex === highlightIndex ? "primary.main" : undefined;

    return {
      minWidth: "20px",
      width: "30px",
      height: "30px",
      color: color,
      backgroundColor: backgroundColor,
      ":hover": {
        backgroundColor: hoverBgColor,
      },
      ...sx
    };
  };

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    onChosen(parseInt((event.target as HTMLInputElement).value));
    setHighlightIndex(index);
    setGuestNum_selector('')
  };

  const handleOnSelect = (event: SelectChangeEvent) => {
    setGuestNum_selector(event.target.value as string);
    onChosen(parseInt((event.target.value)));
    setHighlightIndex(-1)
  }

  return (
    <Box
      sx={{
        my: "10px",
        display: "flex",
        justifyContent: "flex-start",
        "> button": { mr: "4px", border: "1px solid #e7e4e4" },
      }}
    >
      {guestNumArr_picker.map((guestNum, index) => (
        <Button
          sx={SetButtonStyle(index)}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClick(event, index)
          }
          value={guestNum}
          data-cy={"guestNum-"+guestNum}
        >
          {guestNum}
        </Button>
      ))}

      <FormControl sx={{ maxHeight: "30px", width: "90px" }} size="small" fullWidth>
      <InputLabel id="guestNum-selector" sx={{fontSize: "12px"}}>
        {lang === "tc" ? "6人以上" : "above 6"}
      </InputLabel>
        <Select
          labelId="guestNum-selector"
          value={guestNum_selector}
          label={lang === "tc" ? "以上" : "above"}
          onChange={handleOnSelect}
          SelectDisplayProps={{style: { maxHeight: "30px", padding: "0px 14px", color: guestNum_selector ? "#d94a4f" : "", fontSize: "16px", fontWeight: guestNum_selector ? 900 : "", backgroundColor: guestNum_selector ? "#fff2f2" : ""}}}
          inputProps={{style: { maxHeight: "30px",  }}}
          data-cy={"guestNum-selector"}
        >
          {guestNumArr_selector.map((guestNum) => (
            <MenuItem value={guestNum} data-cy={"guestNum-"+guestNum}>{guestNum}</MenuItem>
          ))}
        </Select>
        </FormControl>
    </Box>
  );
}

export default GuestNumPicker;
