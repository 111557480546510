import { Box, Button, Stack, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLanguageContext } from '../LanguageContextProvider'
import dayjs from 'dayjs'
import { domainURL } from '../Variables/Domain'

const CancelPage = () => {

    const location = useLocation();
    const lang = useLanguageContext();

    const search = location.search.substring(1)

    const [validBooking, setValidBooking] = useState<{status: boolean, message: string} | null>(null)
    const [bookingDetails, setBookingDetails] = useState<{start_time: string, guestNum: number} | null>(null)
    const [deleted, setDeleted] = useState<boolean | null>(null)

    useEffect(() => {
        axios
        .get(`${domainURL}/api/client/bookingValid`, {
          params: {
            search: location.search.substring(1),
          },
        })
        .then((response) => {
            //console.log(response.data)
            setValidBooking({status: response.data.status, message: response.data.message})
            //console.log(response.data.booking)
              setBookingDetails(response.data.booking)
        })
    }, [])

    const handleOnCancel = () => {
        //console.log(search)
        
        axios.delete(`${domainURL}/api/client/`, {
            data: {search: search},
        })
        .then((response) => {
            if (response.data.status === "success") {
                setDeleted(true)
            } else {
                setDeleted(false)
            }
          });
    }

  return (
    <>
        {validBooking && validBooking.status && !deleted &&
        <Stack sx={{alignItems: "center"}}>
        
        {lang === "tc" ?
        <Typography sx={{fontSize: "20px", fontWeight: 900}}>
            你是否確定要取消預訂？
        </Typography> :
        <Typography sx={{fontSize: "18px", fontWeight: 900, wordBreak: "break-word", fontFamily: "AvenirNextBold"}}>
            Do you confirm to cancel this booking?
        </Typography> }

        <Typography>
            {lang === "tc" ? "預訂詳情" : "Booking Details" }
        </Typography>

        <Stack direction={"row"}>
            <Typography>{lang === "tc" ? "日期：" : "Date:" }</Typography>
            <Typography>{dayjs(bookingDetails?.start_time).format('YYYY-MM-DD')}</Typography>
        </Stack>
        <Stack direction={"row"}>
            <Typography>{lang === "tc" ? "時間：" : "Time:" }</Typography>
            <Typography>{dayjs(bookingDetails?.start_time).format('HH:mm')}</Typography>
        </Stack>
        <Stack direction={"row"}>
            <Typography>{lang === "tc" ? "人數：" : "Guest No.:" }</Typography>
            <Typography>{bookingDetails?.guestNum}</Typography>
        </Stack>
        <Button variant="contained" onClick={handleOnCancel}>{lang === "tc" ? "取消預訂" : "Cancel Booking" }</Button>
        </Stack>
        }

        {deleted &&
        <Stack sx={{alignItems: "center"}}>
            <Typography sx={{fontSize: "20px", fontWeight: 900, fontFamily: "AvenirNextBold"}}>
                {lang === "tc" ? "預約已被取消" : "Booking Cancelled" }
            </Typography>
            <Button variant="contained" href='https://ivanthekozak.com.hk/tc/booking.php'>
                {lang === "tc" ? "進行新的預約" : "Make a New Booking" }
            </Button>
        </Stack>}
        
        {validBooking && !validBooking.status && validBooking.message === "Booking deleted"
        && 
        <Stack sx={{alignItems: "center"}}>
            <Typography sx={{fontSize: "20px", fontWeight: 900}}>
                {lang === "tc" ? "預約已被取消" : "Booking Cancelled" }
            </Typography>
            <Button variant="contained" href='https://ivanthekozak.com.hk/tc/booking.php'>
                {lang === "tc" ? "進行新的預約" : "Make a New Booking" }
            </Button>
        </Stack>
        }
        {validBooking && !validBooking.status && validBooking.message === "Booking passed"
        &&
        <Stack sx={{alignItems: "center"}}>
            {lang === "tc" ?
                <Typography sx={{fontSize: "20px", fontWeight: 900}}>
                預約時間已過
            </Typography> :
            <Typography sx={{fontSize: "14px", fontWeight: 900, wordBreak: "break-word", fontFamily: "AvenirNextBold"}}>
                Reservation time has passed
            </Typography>}
            <Button variant="contained" href='https://ivanthekozak.com.hk/tc/booking.php'>
                {lang === "tc" ? "進行新的預約" : "Make a New Booking" }
            </Button>
        </Stack>
        }
        {validBooking && !validBooking.status && validBooking.message === "Invalid booking id"
        && 
        <Stack sx={{alignItems: "center"}}>
            <Typography sx={{fontSize: "20px", fontWeight: 900}}>
                {lang === "tc" ? "預約不存在" : "Invalid Booking ID" }
            </Typography>
            <Button variant="contained" href='https://ivanthekozak.com.hk/tc/booking.php'>
                {lang === "tc" ? "進行新的預約" : "Make a New Booking" }
            </Button>
        </Stack>}
    </>

  )
}

export default CancelPage