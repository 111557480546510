export const validateEmail = (email:string, allowEmpty: boolean) => {

    if(allowEmpty){
        if(email === "") return true
    }

    const regex = /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/

    const emailRegex = regex;
    if (!emailRegex.test(email)) {
        return false
    } else {
        return true
    }
};