import React, { useEffect, useState } from "react";
import PageButton from "../PageButton";
import PersonalInfo, { PersonalInfoProps } from "./PersonalInfo";
import { PageProps } from "./PageOne";
import PageControl from "./PageControl";
import { matchIsValidTel } from "mui-tel-input";
import { validateEmail } from "../utility/Functions/ValidateEmail";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLanguageContext } from "../LanguageContextProvider";
import { depositPerGuest, minChargeSpecialDay } from "./BookingForm";

function PageThree({
  onPageChanged,
  onInfoChanged,
  info,
  currentPage,
}: PageProps) {
  // Page Control Checking
  const [passable, setPassable] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);
  const [otherChecked, setOtherChecked] = useState<{
    checked: boolean;
    message: string;
  }>({ checked: false, message: "" });
  const [agreementChecked, setagreementChecked] = useState<boolean>(false);

  let { name, mobile, email, remark, birthday } = { ...info.personalInfo };
  let { channel } = { ...info };
  const lang = useLanguageContext();

  const isPassable = () =>{
    if(info.deposit || info.specialDate){
      return agreementChecked
    }
    return true
  }

  useEffect(() => {
    if (
      name !== "" &&
      name !== undefined &&
      matchIsValidTel(mobile !== undefined ? mobile : "") &&
      validateEmail(email !== undefined ? email : "", false) &&
      (remark?.length ?? 0) <= 100 &&
      (birthday?.length ?? 0) <= 50 &&
      (channel.length !== 0 ||
        (otherChecked.checked && otherChecked.message !== ""))
    ) {
      setPassable(true);
    } else {
      setPassable(false);
    }
  }, [info, otherChecked]);

  const handleChangePage = (pageChange: number) => {
    if (pageChange === +1) {
      if (passable) {
        if (otherChecked.checked) {
          let channel_cloned = [...info.channel];
          channel_cloned.push("Other - ".concat(otherChecked.message));
          onInfoChanged({ ...info, channel: channel_cloned });
          //console.log(info);
        }
        onPageChanged(pageChange);
      } else {
        setChecking(true); // check only when NEXT is clicked
      }
    } else onPageChanged(pageChange);
  };

  const handlePersonalInfoChanged = (personalInfo: PersonalInfoProps) => {
    let info_cloned = { ...info };
    info_cloned.personalInfo = personalInfo;
    onInfoChanged(info_cloned);
  };

  const checkList = [
    {
      tc: "親友推薦",
      en: "Recommended by Families, Friends or Colleagues",
      db: "Recommendations",
    },
    { tc: "OpenRice", en: "OpenRice", db: "Openrice" },
    {
      tc: "社交媒體 (Facebook, Instagram, etc)",
      en: "Social Medias (Facebook, Instagram, etc)",
      db: "Social Media",
    },
    {
      tc: "網上廣告 (Google Ads, Google Map, Instagram, etc)",
      en: "Online Advertisement (Google Ads, Google Map, Instagram, etc)",
      db: "Online Ads",
    },
    {
      tc: "搜尋引擎 - 非廣告 (Google, Yahoo, etc)",
      en: "Search Engine - non-advertisement (Google, Yahoo, etc)",
      db: "Search Engine",
    },
    { other: true, tc: "其他 (請註明)", en: "Other {Please specified}" },
  ];

  const handleChannelChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (checkList[index]?.other) {
      setOtherChecked({ ...otherChecked, checked: event.target.checked });
      if (!event.target.checked) {
        setOtherChecked({
          ...otherChecked,
          checked: event.target.checked,
          message: "",
        });
      }
      return;
    }

    if (event.target.checked) {
      if (checkList[index].db !== undefined) {
        let channel_cloned = [...info.channel];
        channel_cloned.push(checkList[index].db ?? "");
        onInfoChanged({ ...info, channel: channel_cloned });
      }
    } else {
      let itemIndex = channel.indexOf(checkList[index].db ?? "");
      if (itemIndex !== -1) {
        let channel_cloned = [...info.channel];
        channel_cloned.splice(itemIndex, 1);
        onInfoChanged({ ...info, channel: channel_cloned });
      }
    }
  };

  return (
    <div>
      <PersonalInfo
        info={info.personalInfo}
        onInfoChanged={handlePersonalInfoChanged}
        checking={checking}
      />

      {false && JSON.stringify(channel)}
      {false && JSON.stringify(otherChecked)}
      {false && JSON.stringify(passable)}
      {false && JSON.stringify(checking && channel.length === 0)}
      <FormControl
        required
        error={checking && channel.length === 0 && !otherChecked.checked}
      >
        <Typography sx={{ fontSize: "14px" }}>
          {lang === "tc" ? "請問你是如何首次得知我們餐廳呢？" : "How did you discover our restaurant?"}
        </Typography>
        <FormLabel focused={false} sx={{ fontSize: "12px", mb: "4px" }}>
          {lang === "tc" ? "可複選" : "Choose any that apply"}
        </FormLabel>
        <FormGroup>
          {checkList.map((checkItemObj, index) => {
            if (lang === "tc" || lang === "en") {
              if (checkList[index]?.other) {
                return (
                  <>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          size="small"
                          checked={otherChecked.checked}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChannelChanged(e, index)
                          }
                        />
                      }
                      label={checkItemObj[lang]}
                      componentsProps={{
                        typography: { style: { fontSize: "14px" } },
                      }}
                      sx={{my: "2px"}}
                    />
                    {checkList[index]?.other && otherChecked.checked && (
                      <>
                        <TextField
                          error={otherChecked.message === ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setOtherChecked({
                              ...otherChecked,
                              message: e.target.value,
                            })
                          }
                          size="small"
                          placeholder={
                            lang === "tc" ? "請註明" : "Please specified"
                          }
                          sx={{mb: "4px"}}
                          inputProps={{ style: { fontSize: "16px" }}}
                        />
                        {checking &&
                          otherChecked.checked &&
                          otherChecked.message === "" && (
                            <FormHelperText error={true}>
                              {lang === "tc" ? "請註明" : "Please specified"}
                            </FormHelperText>
                          )}
                      </>
                    )}
                  </>
                );
              }

              return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        size="small"
                        checked={channel.includes(checkList[index].db ?? "")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChannelChanged(e, index)
                        }
                        data-cy={`channel-${index}`}
                      />
                    }
                    label={checkItemObj[lang]}
                    componentsProps={{
                      typography: { style: { fontSize: "14px" } },
                    }}
                    sx={{my: "2px"}}
                  />
              );
            }
          })}
        </FormGroup>
        {checking && channel.length === 0 && !otherChecked.checked && (
          <FormHelperText>
            {lang === "tc" ? "請選擇至少一項" : "Please choose at least one"}
          </FormHelperText>
        )}
      </FormControl>

      {info.specialDate ? 
      <FormControlLabel
        label={
          lang === "tc"
            ? `我已明白當日將有最低消費$${minChargeSpecialDay}/位，並需預繳 $${depositPerGuest}/位 訂金，詳情將於稍後以電郵通知。`
            : `I understand that there will be a minimum charge of $${minChargeSpecialDay}/guest and a deposit of $${depositPerGuest}/guest is required. Details will be sent by email.`
        }
        sx={{ mb: 1 }}
        componentsProps={{ typography: { style: { fontSize: "12px" } } }}
        control={
          <Checkbox
            checked={agreementChecked}
            onChange={() => setagreementChecked(!agreementChecked)}
            data-cy={"agreement"}
          />
        }
      />
      : info.deposit ? (
        <FormControlLabel
          label={
            lang === "tc"
              ? `我已明白7人或以上訂座需預繳 $${depositPerGuest}/位 訂金，詳情將於稍後以電郵通知。`
              : `I understand that a deposit of $${depositPerGuest}/guest is required for reservation for 7 people or above. Details will be sent by email.`
          }
          sx={{ mb: 1 }}
          componentsProps={{ typography: { style: { fontSize: "12px" } } }}
          control={
            <Checkbox
              checked={agreementChecked}
              onChange={() => setagreementChecked(!agreementChecked)}
              data-cy={"agreement"}
            />
          }
        />
      ) : null}
      <PageControl
        passable={isPassable()}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        forwardTab="Book"
        backwardTab="Back"
      />
    </div>
  );
}

export default PageThree;
