import { Box, CircularProgress, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { developmentObj, domainURL } from "../Variables/Domain";

interface Props {
    children: React.ReactNode
    isPublic?: boolean
}

const ValidPage = ({children, isPublic = false}: Props) => {
  const [validPath, setValidPath] = useState<boolean | null>(developmentObj.local_development || isPublic ? true : null);

  const location = useLocation();
  useEffect(() => {
    if(developmentObj.local_development) return
    if(isPublic) return
    
    axios
      .get(`${domainURL}/api/client/linkValid`, {
        params: {
          search: location.search.substring(1),
        },
      })
      .then((response) => {
        //console.log(response.data)
        setValidPath(response.data);
        if(!response.data){
            const timeoutId = setTimeout(() => {
                window.location.href = "https://www.ivanthekozak.com.hk";
              }, 1000);
  
              return () => {
                clearTimeout(timeoutId); // This clears the timeout if the component unmounts before the delay is reached
              }; 
        }
      });
  }, []);

  return (
    <Box sx={{ bgcolor: "#eaeefa", display: "flex", justifyContent: "center" }}>
      <Paper
        elevation={2}
        sx={{ width: {xs: "100%", sm: "80%"}, m: 1, minHeight: "80vh", maxWidth: "600px", p: 4 }}
      >
        {validPath === null && <Box sx={{display: "flex",justifyContent: "center"}}><CircularProgress /></Box>}
        {validPath && children}
        {validPath === false && <div>This page does not exist</div>}
      </Paper>
    </Box>
  );
};

export default ValidPage;
