import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { validateEmail } from '../Functions/ValidateEmail'

type Props = TextFieldProps & {
    inputRef?: React.Ref<any> | undefined
    sx?: React.CSSProperties
    label?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
    placeholder?: string
    defaultValue?: string | null
    inputProps?: InputBaseComponentProps | undefined
    disabled?: boolean
    allowEmpty?: boolean
    required?: boolean
    checking?: boolean
}

const EmailTextField = (
    {   onChange,
        defaultValue,
        allowEmpty = false,
        checking = true,
        ...props} : Props
) => {
    //console.log("defaultValue in email: ", defaultValue)
    //console.log("checking:",checking)
    const [email, setEmail] = useState<string>((defaultValue === null || defaultValue === undefined) ? "" : defaultValue);
    const [error, setError] = useState<boolean>(false);
    
    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);

        if(onChange){
            onChange(event)
        }
    };


    useEffect(() => {
        if(checking)
        {let validEmail = validateEmail(email, allowEmpty);
            if(validEmail){
                setError(false)
            }
        else setError(true)}

    }, [email, checking]);

  return (
    <TextField
        onChange={handleEmailChange}
        value={email}
        error={error}
        
        {...props}
  />
  )
}

export default EmailTextField