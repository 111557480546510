import React from 'react'
import PageButton from '../PageButton'
import { Box } from '@mui/material'

interface Props{
    currentPage: number
    handleChangePage: (pageChange: number) => void
    passable: boolean
    forwardTab: string
    backwardTab: string
}

const PageControl = ({currentPage, handleChangePage, passable, forwardTab, backwardTab}: Props) => {
    //console.log(currentPage, passable)
  return (
    <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
        {currentPage > 1 && <PageButton onClick={handleChangePage} pageChange={-1}>{backwardTab}</PageButton>}
        {currentPage < 4 && <PageButton disabled={!passable} onClick={handleChangePage} pageChange={+1}>{forwardTab}</PageButton>}
    </Box>

  )
}

export default PageControl