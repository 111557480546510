import { useRef, useState } from "react";
import axios from "axios";
import { Box, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { BookingInfo } from "./BookingForm";
import EmailTextField from "../utility/TextFields/EmailTextField";
import CharacterLimitTextField from "../utility/TextFields/CharacterLimitTextField";
import { useLanguageContext } from "../LanguageContextProvider";

const MuiTelInputStyled = styled(MuiTelInput)(({ theme }) => ({
  "& .MuiInputBase-root": {
    paddingLeft: "7px  !important",
  },
}));

interface PersonalInfoProps {
  name: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  remark: string;
  birthday: string;
}

interface Props {
  info: PersonalInfoProps;
  onInfoChanged: (info: PersonalInfoProps) => void;
  checking: boolean;
}

export type { PersonalInfoProps };

export default function PersonalInfo({ info, onInfoChanged, checking }: Props) {
  let { name, email, mobile, remark, birthday } = { ...info };
  const lang = useLanguageContext();
  //console.log(checking && (name === "" || name === undefined))

  const handleChange = (
    changeField: "name" | "mobile" | "email" | "remark" | "birthday",
    event: any
  ) => {
    let info_cloned = { ...info };
    let e = event as React.ChangeEvent<HTMLInputElement>;

    switch (changeField) {
      case "name":
        info_cloned.name = e.target.value;
        break;
      case "mobile":
        info_cloned.mobile = event as string;
        break;
      case "email":
        info_cloned.email = e.target.value;
        break;
      case "remark":
        info_cloned.remark = e.target.value;
        break;
      case "birthday":
        info_cloned.birthday = e.target.value;
        break;
    }
    onInfoChanged(info_cloned);
  };

  return (
    <Box sx={{ "> div": { width: "100%", mb: "20px" } }}>

      <CharacterLimitTextField
        size="small"
        label={lang === "tc" ? "姓名" : "Name"}
        required
        charLimit={50}
        error={checking && (name === "" || name === undefined)}
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange("name", event)
        }
        data-cy="name"
        inputProps={{ style: { fontSize: "16px" } }}
        sx={{".MuiFormLabel-root": {fontSize: "18px"}}}
      />

      <MuiTelInputStyled
      size="small"
        value={mobile}
        error={checking && !matchIsValidTel(mobile !== undefined ? mobile : "")}
        defaultCountry="HK"
        focusOnSelectCountry
        onChange={(value) => handleChange("mobile", value)}
        data-cy="mobile"
        inputProps={{ style: { fontSize: "16px" } }}
        sx={{".MuiFormLabel-root": {fontSize: "18px"}}}
      />

      <EmailTextField
      size="small"
        label={lang === "tc" ? "電郵" : "Email"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange("email", event)
        }
        placeholder="example@gmail.com"
        defaultValue={email}
        inputProps={{ style: { fontSize: "16px" } }}
        sx={{ backgroundColor: "white", ".MuiFormLabel-root": {fontSize: "18px"} }}
        allowEmpty={false}
        required
        checking={checking}
        data-cy="email"
      />

      <CharacterLimitTextField
      size="small"
        label={lang === "tc" ? "備註(100字以內)" : "Guest Remark (100 characters)"}
        multiline
        rows={2}
        charLimit={100}
        value={remark}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange("remark", event)
        }
        data-cy="guestRemark"
        inputProps={{ style: { fontSize: "16px" } }}
        sx={{".MuiFormLabel-root": {fontSize: "18px"}}}
      />

      <CharacterLimitTextField
      size="small"
        label={lang === "tc" ? "甜品碟寫字(50字之內)" : "Message on Dessert Plate (50 characters)"}
        multiline
        rows={2}
        charLimit={50}
        value={birthday}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange("birthday", event)
        }
        data-cy="birthdayRemark"
        inputProps={{ style: { fontSize: "16px" } }}
        sx={{".MuiFormLabel-root": {fontSize: "18px"}}}
      />
    </Box>
  );
}
