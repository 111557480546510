import { Button } from '@mui/material'
import React from 'react'

interface Props {
    onClick: (pageNum: number) => void
    children: string
    pageChange: number
    disabled?: boolean
}

const PageButton = ({onClick, children, pageChange, disabled = false}: Props) => {

  return (
    <Button disabled={disabled} sx={{pt: "6px", fontSize: "16px"}} onClick={() => onClick(pageChange)}>{children}</Button>
  )
}

export default PageButton